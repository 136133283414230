<template>
  <v-app>
    <!--<v-navigation-drawer-->
    <!--      v-model="drawer"-->
    <!--      app-->
    <!--      flat-->
    <!--    >-->
    <!--    <LeftNav ref="leftnav"></LeftNav>-->
    <!--</v-navigation-drawer>-->

    <v-app-bar v-if="$route.name !='Login' && $route.name!='Home'"
      app
      :color="getGeneralSetting.banner && getGeneralSetting.banner.backgroundColor? getGeneralSetting.banner.backgroundColor : 'primary' " 
      prominent
      flat
      outlined
     
      :src="bgImage"
      height="116px"

      class="pt-4"
    >
   
       <!--<v-app-bar-nav-icon  @click="drawer = !drawer"></v-app-bar-nav-icon>-->
        <v-img v-if="getGeneralSetting.displayState == 'LOGO'"
      
          height="80"
          max-width="150"
          contain
          :src="getGeneralSetting.displayLogo"
          @click="gotoHome()"
        ></v-img>
       <div height="80" class="d-flex align-start pt-3" @click="gotoHome()" v-if="getGeneralSetting.displayState == 'TEXT'" ><b :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}">{{getGeneralSetting.customerDisplayName}}</b></div>
          <!--<v-btn text class= "pt-1" @click="gotoHome()">Demo Overview</v-btn>-->
          <!-- <v-btn text color="secondary" class= "pt-1" @click="gotoHub()"> <v-icon class="mr-2">mdi-home</v-icon>  Home</v-btn> -->
           <!-- <v-btn text color="secondary" class= "pt-1" @click="gotoGenAI()">Gen AI</v-btn> -->
          
             <v-spacer></v-spacer>
          
          <!--<v-btn @click="setUserProfileVisibility(!showUserProfile)"  v-if="getLoginUser" text color="white" class= "pt-1">Welcome {{getLoginUser.name}} - {{getLoginUser.role}} </v-btn>-->
          <!-- <span  v-if="getLoginUser"   text :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}"   class= "pt-3">Welcome {{getLoginUser.name}}  </span> -->
          <!-- <v-avatar>
            <img
              :src="userAvatarImage.img"
              :alt="userAvatarImage.alt"
            >
          </v-avatar> -->
          <!-- <div justify="right">
          <settings></settings>
          </div> -->
          
          <v-menu  offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                color="primary"
                class="mt-2 mr-4"
                v-bind="attrs"
                v-on="on"
                icon
                
              >
              <v-avatar>
            <img
              :src="userAvatarImage.img"
              :alt="userAvatarImage.alt"
            >
            
          </v-avatar>
            <v-icon :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}">mdi-menu-down</v-icon>

                <!-- <v-icon :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}" >mdi-cog</v-icon>
              
                <v-icon :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}" >mdi-cog</v-icon> -->
              </v-btn>
            </template>
            <v-list>
              <v-list-item >
                <v-list-item-title>{{this.getLoginUser.name }}</v-list-item-title>
                
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item @click="gotoSettings()">
                
                <v-list-item-title>Edit Tenant</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-pencil</v-icon>
                </v-list-item-icon>
                
              </v-list-item>
              <v-list-item @click="gotoMyTenants()"  v-if="getLoginUser.role=='PLATFORMADMIN'">
                <v-list-item-title >My Tenants</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-domain</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item @click="gotoAdmin()"  v-if="getLoginUser.role=='PLATFORMADMIN'" >
                <v-list-item-title >Manage Tenants</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-cog</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <!-- <v-list-item @click="managePersonalizedViews= true"  v-if="getLoginUser.role=='PLATFORMADMIN'" >
                <v-list-item-title >Manage Views</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-star-cog</v-icon>
                </v-list-item-icon>
              </v-list-item> -->
              <v-list-item @click="showPersonalizedViews= true;getMyPersonalizedViews()"  >
                <v-list-item-title >Views</v-list-item-title>
                <v-list-item-icon>
                  <v-icon small>mdi-star-settings</v-icon>
                </v-list-item-icon>
              </v-list-item>
              
            </v-list>

          </v-menu>
          <!-- <v-btn  @click="logOut()" icon class="mt-2">
            <v-icon :style="{'color': getGeneralSetting.banner? getGeneralSetting.banner.fontColor : 'white'}" >mdi-logout</v-icon>
          </v-btn>     -->
          <!-- <v-btn @click="gotoSettings()" icon>
            <v-icon  dark color="secondary" >mdi-cog</v-icon>
          </v-btn>    
           <v-btn @click="gotoAdmin()" icon>
            <v-icon  dark color="secondary" >mdi-cog</v-icon>
          </v-btn>     -->
          <!--<span>Path {{$router.currentRoute.path}}</span>-->

    </v-app-bar>


    <v-main >
      <v-overlay opacity="0" :value="getShowMessage && getMessage.type=='processing'">
      <v-card color="white">
        <v-container>
          <v-row>
            <v-col>
                <ProcessingMessage :message="getMessage" v-if="getShowMessage && getMessage.type=='processing'" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      </v-overlay>
      <v-alert  class="mb-0" v-if="getShowMessage && getMessage.type!='processing'"
        border="left"
        dismissible
        :type="getMessage.type"
        @input="setMessageVisibility"
      >
      {{getMessage.text}}
      
      </v-alert>

      <v-navigation-drawer
      v-model="managePersonalizedViews"
      temporary
      app
      right
      width="90%"

    >
    <v-overlay opacity="0" :value="getShowMessage && getMessage.type=='processing'">
      <v-card color="white">
        <v-container>
          <v-row>
            <v-col>
                <ProcessingMessage :message="getMessage" v-if="getShowMessage && getMessage.type=='processing'" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      </v-overlay>
      <v-alert  class="mb-0" v-if="getShowMessage && getMessage.type!='processing'"
        border="left"
        dismissible
        :type="getMessage.type"
        @input="setMessageVisibility"
      >
      {{getMessage.text}}
      
      </v-alert>
      <v-toolbar outlined flat>
        <v-toolbar-title>
           Views
        </v-toolbar-title>
              <v-btn  @click="createPersonalizedView()" text class="ml-4" color="primary">
                  Create a View
                </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="managePersonalizedViews=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card flat>
        <v-container v-if="personalizedViews.length==0">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" class="d-flex justify-center">
              There are no personalized views
            </v-col>
            <v-col class="d-flex justify-center" >
                <v-btn  @click="createPersonalizedView()" outlined color="primary">
                  Create a View
                </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container >
          <v-row>
            <v-col cols="3">
                  <v-list>
                    <v-list-item-group v-model="activeView" color="primary">
                      <v-list-item
                        v-for="view in personalizedViews"
                        :key="view.viewId"
                        :value="view"
                      >
                      
                        <v-list-item-content>
                          <v-list-item-title v-text="view.viewName"></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
            </v-col>
            <v-col cols="9" v-if="activeView && activeView.viewId">
              <v-toolbar flat>
                <v-spacer></v-spacer>
                <v-btn color="primary" flat @click="saveView()"> 
                  Save view
                </v-btn>

                <v-btn color="error" flat class="ml-4" @click="deletePersonalizedView()"> 
                  Delete view
                </v-btn>

              </v-toolbar>
              <v-card flat>
              <v-text-field outlined label="View Name"
              v-model="activeView.viewName"
              >
              </v-text-field>

              </v-card>
              <v-card
              
              >
              <v-card-title>Apps</v-card-title>
                <v-treeview selectable selection-type="leaf"  v-model="activeView.apps" :items="getProductTree"></v-treeview>
              </v-card>

            </v-col>
          </v-row>
        </v-container>
      </v-card>


      </v-navigation-drawer>
      <v-navigation-drawer
      v-model="showPersonalizedViews"
      temporary
      app
      right
      width="30%"
      hide-overlay
    >
    
      <v-toolbar outlined flat>
        <v-toolbar-title>
           Views
        </v-toolbar-title>
              <v-btn  @click="openManageViews()" text class="ml-4" color="primary">
                  Manage Views
                </v-btn>
        <v-spacer></v-spacer>
        <v-btn icon @click="showPersonalizedViews=false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card flat>
        <v-container v-if="personalizedViews.length==0">
          <v-row class="d-flex justify-center align-center">
            <v-col cols="12" class="d-flex justify-center">
              There are no personalized views
            </v-col>
            <v-col class="d-flex justify-center" >
                <v-btn  @click="openManageViews()" outlined color="primary">
                  Manage Views
                </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container >
          <v-row>
            <v-col cols="12">
                  <v-list>
                    <v-list-item-group  color="primary">
                      <v-list-item class="productitem" @click="launchView(view)"
                        v-for="view in personalizedViews"
                        :key="view.viewId"
                        :value="view"
                      >
                      
                        <v-list-item-content class="border text-subtitle-1  border-t-lg">
                          <v-list-item-title   v-text="view.viewName"></v-list-item-title>
                        </v-list-item-content>
                       <v-list-item-icon class="mr-2" >
                                <v-icon  color="primary" >mdi-open-in-new</v-icon>
                              </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
            </v-col>
         
          </v-row>
        </v-container>
      </v-card>


      </v-navigation-drawer>
      <v-fade-transition mode="out-in">
       <router-view ></router-view>
      </v-fade-transition>

        <!-- <LoginPopUp/> -->

        
    </v-main>


   

 

    
    
  </v-app>
</template>

<script>
/*eslint-disable*/
import LoginRedirect from '@/components/LoginRedirect.vue'
import { mapGetters,mapActions } from 'vuex'
//import LeftNav from './components/LeftNav'
import Introduction from './components/Introduction'

import ProcessingMessage from './components/ProcessingMessage.vue'
// import LoginPopUp from './components/LoginPopUpV2'
import { v4 as uuidv4 } from 'uuid';
import {API} from 'aws-amplify'

export default {
  name: 'App',

  components: {
    //LeftNav,
    ProcessingMessage,
    // LoginPopUp,
    Introduction,
    LoginRedirect

    
  },

  data: () =>  { 

       return { viewUserProfile:null,
        showAlert:false,
        setupNeeded:false,
        showPersonalizedViews:false,
        personalizedViews:[],
        managePersonalizedViews: false,
        activeView : {}
        
        }

      
        
        
  },
  mounted(){
    this.setupNeeded=window.setupNeeded
    
    
    // if (window.setupNeeded){
    //   //this.gotoSetup()

    // }
    // else{

    // this.updateCustomerSetting(window.generalSetting)
    // this.updateCustomerProducts (window.customerProducts)
    // this.updateAnalyticsProducts (window.analyticsProducts)
    // this.updateUsers (window.users)
    // this.updateSubscriptionPlans (window.subscriptionPlans)
    // }
    // this.fetchAnonymousDashboardEmbedUrl()
    // this.fetchSessionDashboardEmbedUrl()
    //getOpenIdToken();
  },
  computed:{
    activeProducts(){
      const result = this.getCustomerProducts.filter(isActive);

      function isActive(product) {
        return product.productStatus  == 'active';
      }
      return result
    },
    userAvatarImage(){
      return {
        img: "https://badgephotos.corp.amazon.com/?uid=" + this.getLoginUser.email.split("@")[0],
        alt: this.getLoginUser.name.substring(0,1)
        }
    },
    bgImage(){
      var i = null  
      if (this.getGeneralSetting.splashScreen != null && this.getGeneralSetting.splashScreen.backGround.imageUrl!= null && this.getGeneralSetting.splashScreen.backGround.imageUrl != '')
        i = this.getGeneralSetting.splashScreen.backGround.imageUrl

      return i

    },
    currentChat (){
      if (!this.getLoginUser || !this.getLoginUser.genAiChats ) return []
      const result = this.getLoginUser.genAiChats.filter(isCurrent);

      function isCurrent(product) {
        return product.isCurrent ;
      }
      return result
    },
    getProductTree(){
      var tree = []
      this.activeProducts.forEach(pGroup=>{
        var gp = {}
        
          gp.id= pGroup.templateId,
          gp.name= pGroup.productDisplayName,
          gp.children=[]
        
        this.getGeneralSetting.genAiSettings.products.forEach(product=>{
          if (product.productGroupId == pGroup.templateId){
            gp.children.push({id: product.productId, name: product.productDisplayName + " - " + product.productStatus})
          }
        })
        tree.push(gp)


      })
      return tree
    },
    pinnedChats (){
      if (!this.getLoginUser || !this.getLoginUser.genAiChats)  return []
      const result = this.getLoginUser.genAiChats.filter(isPinned);

      function isPinned(product) {
        return product.isPinned ;
      }
      return result
    },
    isInsightsView(){
      if (this.$route.name =="Insight Viewer") return true
      return false
    },
      ...mapGetters('appSetting', [
      'getCustomerProducts'
    ]),
      ...mapGetters('generalSetting', [
      'getGeneralSetting'
    ]),
    ...mapGetters('navigation', [
      'getView','showUserProfile', 'getGenAiChatVisibility','getGenAiChats', 'getShowMessage','getMessage'
    ]),
    
    ...mapGetters('user', [
      'getLoginUser'
    ])
    },
    methods:{
      
           
      ...mapActions('quickSight', ['fetchAnonymousDashboardEmbedUrl','fetchSessionDashboardEmbedUrl', 'addToGroup' ]),
       ...mapActions('generalSetting', ['updateCustomerSetting' ]),
       ...mapActions('appSetting', ['updateCustomerProducts', 'updateAnalyticsProducts','updateSubscriptionStatus','updateSubscriptionPlans' ]),
        ...mapActions('user', ['updateLoginUser', 'updateUsers','addNewGenAiChat']),
        ...mapActions('navigation', ['setView', 'setUserProfileVisibility', 'setGenAiChatVisibility', 'setShowMessage','setMessage']),
      launchView(v){
        window.open(window.location.protocol + "//" +  window.location.host + "/"+ v.tenantId + "/hub?viewId="+v.viewId,'_blank');


      },
      openManageViews(){
        this.showPersonalizedViews = false
        this.managePersonalizedViews = true
        if (this.personalizedViews && this.personalizedViews.length > 0 )
        this.activeView = this.personalizedViews[0]
      },
      setMessageVisibility(e){
       this.setShowMessage (e)
      },
      gotoHome(){
          this.$router.push({name:'TenantHub', params:{tenantId:localStorage.getItem("tenantId")}})
        
        
      },
      gotoHub(){
        this.$router.push({name:'Hub'})
      },
      gotoGenAI(){
        this.$router.push({name:'GenAILanding'})
      },
      gotoLogin(){
        this.$router.push({name:'Login'})
      },
      gotoSetup(){
        this.$router.push({name:'Setup'})
      },
      gotoAnalyticsPricing(){
        this.$router.push({name:'AnalyticsPricing'})
      },
      gotoAdmin(){
        this.$router.push({name:'Admin'})
      },
      gotoSettings(){
        this.$router.push({name:'Tenant Settings', params:{tenantId: localStorage.getItem("tenantId")}})
      },
      gotoMyTenants(){
        this.$router.push({name:'MySettings', params:{tenantId: localStorage.getItem("tenantId")}})
      },
      getMyPersonalizedViews(){
        var self=this
        self.setShowMessage (true)
      self.setMessage({type:'processing',
      text: 'Getting views' 
      })
        var self=this
        this.personalizedViews=[]
        var v = {}
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        API.post("genAIUser","/" + localStorage.getItem("tenantId") + "/my/views"+ "?action=listMyViews&tenantId=" + localStorage.getItem("tenantId")  +"&token=" + this.getLoginUser.accessToken ,
        {body: v}
        
        
        ).then((r)=> {
                    console.log(r)
                    self.personalizedViews= r.Items
                    self.personalizedViews.forEach(view=> {
                      view.apps= JSON.parse(view.apps)
                    
                    })
                    self.setShowMessage (false)
      
    
                })
                .catch((error) => {
                  
                  })
        
      },
      saveView (){
        var self=this
      
      self.setShowMessage (true)
      self.setMessage({type:'processing',
      text: 'Saving view' 
      })
        if (this.activeView.viewId.includes("new-")){
          this.activeView.viewId = this.activeView.viewId.replace("new-","")
          this.addPersonalizedView()
        }
        else{
          this.updatePersonalizedView()
        }
      },
      createPersonalizedView(){

        var newView ={}
        newView.viewId =  "new-" + uuidv4();
        newView.userId = this.getLoginUser.userId
        newView.tenantId = localStorage.getItem("tenantId")
        newView.viewName = "New View"
        newView.status="active"
        newView.apps=[]
        this.personalizedViews.push(newView)

      },
      addPersonalizedView(){
        var self=this
        var v = JSON.parse(JSON.stringify(this.activeView))
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        API.post("genAIUser","/" + this.$route.params.tenantId +"/my/views"+ "?action=createMyView&tenantId=" + this.$route.params.tenantId  +"&token=" + this.getLoginUser.accessToken ,
        {body: v}
        
        
        ).then((r)=> {
        
            
      
      self.setShowMessage (true)
      self.setMessage({type:'success',
      text: 'View saved successfully' 
      })

                })
                .catch((error) => {
                  
                  
                  })
                            
                          

      },
      updatePersonalizedView (){
        var self=this
                var v = JSON.parse(JSON.stringify(this.activeView))
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        API.post("genAIUser","/" + this.$route.params.tenantId +"/my/views"+ "?action=updateMyView&tenantId=" + this.$route.params.tenantId  +"&token=" + this.getLoginUser.accessToken ,
       {body: v}
        
        
        ).then((r)=> {
                      
      self.setShowMessage (true)
      self.setMessage({type:'success',
      text: 'View saved successfully' 
      })

                })
                .catch((error) => {
                  
                  // console.log(error.response);
                  // self.setShowMessage (true)
                  // self.setMessage({type:'error',
                  // text: error.response.data
                  })
                            
      },
      deletePersonalizedView (){
var self=this
              var v = JSON.parse(JSON.stringify(this.activeView))
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        API.post("genAIUser","/" + this.$route.params.tenantId +"/my/views"+ "?action=deleteMyView&tenantId=" + this.$route.params.tenantId  +"&token=" + this.getLoginUser.accessToken ,
        {body: v}
              

        
        ).then((r)=> {
                    
                self.setShowMessage (true)
              self.setMessage({type:'success',
              text: 'View deleted successfully' 
              })


                })
                .catch((error) => {
                        self.setShowMessage (true)
                        self.setMessage({type:'error',
                        text: 'Unable to delete the view' + error 
                        })

                  
                  })
          
      },
      launchPersonalizedView (){

      },
      getViewDetails(viewId){
        var self=this
        self.setShowMessage (true)
              self.setMessage({type:'processing',
              text: 'Getting view details' 
              })
              var v = JSON.parse(JSON.stringify(this.activeView))
        v.tenantId = this.$route.params.tenantId 
        v.userId= this.getLoginUser.userId
        v.viewId = viewId
        API.post("genAIUser","/" + this.$route.params.tenantId +"/my/views"+ "?action=getMyView&tenantId=" + this.$route.params.tenantId  +"&token=" + this.getLoginUser.accessToken ,
        {body: v}
              

        
        ).then((r)=> {
                    
                self.setShowMessage (false)
              self.setMessage({type:'success',
              text: 'View fetched successfully' 
              })

              self.setView ( r.Items[0])


                })
                .catch((error) => {
                        self.setShowMessage (true)
                        self.setMessage({type:'error',
                        text: 'Unable to fetch the view' + error 
                        })

                  
                  })

      },
    
      
      logOut(){
        this.updateSubscriptionStatus({id:"analytics", status:"inactive"})
        this.updateLoginUser({})
        this.gotoLogin()
      },
      async subscribeToEmail(){
     
         this.addToGroup("TravelDemo2_Subscription").then(function(s){
          // alert("Success")
          // alert (s)
         })
         
        },
      
      updateCurrentChatName(e){
        console.log(e)
      }
      }
      
    
    

};
</script>
<style scoped>
.productitem{

border-top-style: none;
  border-right-style: none;
  border-bottom-style: dashed;
  border-left-style: none;
  border-width: 1px;
  border-color:rgba(0, 0, 0, 0.15)
}
</style>
