  <template>
            <v-overlay
          absolute="false"
          opacity="1"
          value="true"
          z-index="2001"
          light 
          color="white"
     
        >
  <v-container class="fill-height">
    <v-row>
        <v-col class="d-flex justify-center align-center">
            <v-card light flat outlined >
                <v-container>
                    <v-row transition="slide-x-transition">
                        <v-col v-if="loginLoader" class="ma-12 pa-12">
                          <div v-if="progressMessages.length>0">
                               <div class="font-weight-light text-h4" v-for="message in progressMessages" :key="message.id"> 
                               <v-progress-circular   v-if="message.status=='inprogress'"
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                    <v-icon color="success" x-large v-else :value="100">mdi-check-circle</v-icon>
                                    {{message.message}} 
                               </div>
                          </div>
                        <div v-else>
                          <v-progress-circular  
                                    indeterminate
                                    color="primary"
                                    ></v-progress-circular>
                                    
                               </div>
                        
                        </v-col>
                        <v-col  v-else class="ma-12 pa-12">
                               
                               <span class="ml-3 text-h4 font-weight-light"> Welcome, {{getLoginUser.name}} </span>

                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-col>
    </v-row>
  </v-container>
            </v-overlay>
  </template>
  <script>
import { mapGetters,mapActions } from 'vuex';
import {API} from 'aws-amplify'

  export default {
    name: 'LoginRedirect',

    data: () => ({
        cognitoDomain: 'https://lifex-dev.auth.us-west-2.amazoncognito.com',
        cognitoClientId: '4eodbtp7kk54vdgo3t10lc7r16',
        cognitoRedirectUrl: 'http%3A%2F%2Flocalhost%3A8080',
      mode:'login',
     user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      email:null,
      username:null,
      password:null,
      pwdcode:null,
      progressMessages:[],
      errorMessage:null,
      successMessage:null,
      loginLoader:true,
      signupLoader:false,
      confirmSignUpLoader:false,
      requiredRules:[
        v=> !!v || 'Required field'

      ],

      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
    }),
    computed:{
      ...mapGetters('user', ["getUsers", "getLoginUser"])  ,
      ...mapGetters('generalSetting', [
      'getGeneralSetting'
    ])
    },
    
     mounted(){
      API.get("genAIUser","/login/"+ this.$route.params.tenantId).then(result=> {

  console.log(result)
         
                  if (this.getLoginUser.name == null){
                    const params = new URLSearchParams(window.location.search)
                      
                      var code=null
                      if (params.has("code")){
                        code=params.get("code") 
                      }
                      if (!code){
                        window.localStorage.setItem("tenantId", result.tenantId)
                        if (this.$route.query.forwardTo == null)
                          window.localStorage.removeItem("forwardTo")
                        else
                          window.localStorage.setItem ("forwardTo", this.$route.query.forwardTo)
                        this.authenticateUser(result)
                      }
                    else{
                        this.getTokens(code)
                      
                      
                    }

                  }
              return true

 })
 

     }
  
    
    
    ,
    methods:{

       ...mapActions('generalSetting', ['updateCustomerSetting' ]),
       ...mapActions('appSetting', ['updateCustomerProducts', 'updateAnalyticsProducts','updateSubscriptionStatus','updateSubscriptionPlans' ]),
        ...mapActions('user', ['updateLoginUser', 'updateUsers','addNewGenAiChat']),
        ...mapActions('navigation', ['setUserProfileVisibility', 'setGenAiChatVisibility', 'setShowMessage','setMessage']),
    

  authenticateUser(result){
    this.progressMessages=[]
    this.progressMessages.push({status:"inprogress", message: "Redirecting to authenticate", id: "1"})
    var r= window.location.protocol + "//" + window.location.host

    window.location.href=`${result.cognitoDomain}/oauth2/authorize?client_id=${result.cognitoClientId}&redirect_uri=${r}&response_type=code&scope=email+openid+phone+profile`
  },
  getTokens(code){
     var self=this
     this.progressMessages = []
     this.progressMessages.push({status:"inprogress", message: "Fetching your profile", id: "1"})
     this.progressMessages.push({status:"inprogress", message: "Fetching demo details", id: "2"})
     API.get("authenticate","/"+  this.$route.params.tenantId + "/code/token?code="+ code ).then(result=> {
                  
                  var user = {
                  userId:result.userInfo.sub,
                  name:result.userInfo.given_name + ' ' + result.userInfo.family_name,
                  accessToken:result.access_token,
                  email: result.userInfo.email
                  
                }

                self.progressMessages[0].message = "Fetched user profile"
                self.progressMessages[0].status = "complete"
                

                API.post("genAIAdmin","/admin/adminId/tenants"+ "?action=getLoggedUserRole&tenantId=" + self.$route.params.tenantId  +"&token=" + user.accessToken ,null).then((r)=> {
                    user.role=r.role
                    self.switchUser(user)
                    this.getTenantDetails()
                })
                .catch((error) => {
                  
                  console.log(error.response);
                  self.setShowMessage (true)
                  self.setMessage({type:'error',
                  text: error.response.data
                  })
                            
                          });

                
                  
                 

    })
    .catch((error) => {
      self.setShowMessage (true)
    self.setMessage({type:'error',
    text: error.response.data
    })
      console.log(error.response);
    });

  },

  getTenantDetails(){
    var self=this
        API.get("genAIUser","/tenants/"+ this.$route.params.tenantId).then(result=> {

        console.log(result)
         

                // defaultUserSettings.theme.dark =true
                  this.updateCustomerSetting(result.generalSetting)
                  this.updateCustomerProducts (result.customerProducts)
                  this.updateAnalyticsProducts (result.analyticsProducts)
                  this.updateUsers (result.users)
                  this.updateSubscriptionPlans (result.subscriptionPlans)
                  this.$vuetify.theme.themes.light.primary= result.theme.themes.light.primary
                  this.$vuetify.theme.themes.light.secondary= result.theme.themes.light.secondary
                  self.progressMessages[1].message = "Fetched demo details"
                self.progressMessages[1].status = "complete"
                self.loginLoader =false
                  setTimeout(function(){
                  if (window.localStorage.getItem("forwardTo")!= null){
                      self.$router.push({path:window.localStorage.getItem("forwardTo")})
                  }
                  else {
                self.$router.push({name:'TenantHub'})
                  }
            }, 1000)
              
              return true

 })
 

  },


        switchUser(user){
          
            if (!user.pic){
             user.pic="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortWaved&accessoriesType=Prescription02&hairColor=Black&facialHairType=BeardLight&facialHairColor=Black&clotheType=CollarSweater&clotheColor=Black&eyeType=Default&eyebrowType=Default&mouthType=Default&skinColor=Brown"
            }
            this.updateLoginUser(user)
            
            
            
        },
        
    }
  }
</script>

  
